import { Config, ConfigKeys } from './config';

class ConfigClient {
  static config: Config;

  /**
   * Initiate the configuration
   */
  static init(config: Config): void {
    ConfigClient.config = config;
  }

  static get<Key extends ConfigKeys>(configName: Key): Config[Key] {
    return ConfigClient.config[configName];
  }
}

export default ConfigClient;
