import AccountBox from '@mui/icons-material/AccountBox';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function ActiveRoleMenuItem({ text }: { text: string }) {
  return (
    <Box sx={{ mx: 2, mb: 2, display: 'flex' }}>
      <AccountBox
        fontSize="small"
        sx={{ mr: 1, color: 'rgba(0,0,0,0.54)' }}
      />

      <Typography sx={{ fontSize: '12px' }}>{text}</Typography>
    </Box>
  );
}

export default ActiveRoleMenuItem;
