import {
  generateApiUrl, KEY_REFRESH_TOKEN, OPEN_CONNECT_API_PARAMS, OPENID_CONNECT_PATH_PATTERNS, REFRESH_TOKENS, USER_PATH_PATTERNS,
} from 'src/constants/api';
import {
  OpenIdConnectTokenResponse,
} from 'src/types/requests/OpenIdConnectTokenResponse';
import { validateResponseErrorMessage } from 'src/types/validators/ResponseErrorMessage';
import { validateResponseErrorsList } from 'src/types/validators/ResponseErrorsList';
import { UserLoginResponse } from 'src/types/validators/UserLoginResponse';

export async function getTokens(authCode: string, redirectUri: string) {
  const route = generateApiUrl(OPENID_CONNECT_PATH_PATTERNS.TOKEN);

  const params = new URLSearchParams();
  params.append(OPEN_CONNECT_API_PARAMS.KEY_CODE, authCode);
  params.append(OPEN_CONNECT_API_PARAMS.KEY_GRANT_TYPE, OPEN_CONNECT_API_PARAMS.GRANT_TYPE_AUTHORIZATION_CODE);
  params.append(OPEN_CONNECT_API_PARAMS.KEY_REDIRECT_URI, redirectUri);

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const options = {
    method: 'POST',
    body: params,
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Token request failed with status ${response.status}`, json);
    }

    return json as OpenIdConnectTokenResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Token request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Token request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Token request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json as OpenIdConnectTokenResponse;
}

export async function refreshTokens(issuer: string, refreshToken: string) {
  const route = `${issuer}/protocol/openid-connect/token`;

  const params = new URLSearchParams();
  params.append(REFRESH_TOKENS.KEY_CLIENT_ID, REFRESH_TOKENS.CLIENT_ID_FRONTEND_CLIENT);
  params.append(REFRESH_TOKENS.KEY_GRANT_TYPE, REFRESH_TOKENS.GRANT_TYPE_REFRESH_TOKEN);
  params.append(KEY_REFRESH_TOKEN, refreshToken);

  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
  };

  const options = {
    method: 'POST',
    body: params,
    headers,
  };

  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Token refresh request failed with status ${response.status}`, json);
    }

    return json as OpenIdConnectTokenResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Token refresh request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Token refresh request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Token refresh request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json as OpenIdConnectTokenResponse;
}

export async function recoverPassword(userName: string) {
  const route = generateApiUrl(USER_PATH_PATTERNS.RECOVER_PASSWORD);
  const headers = {
    'Content-Type': 'application/json',
  };
  const body = {
    username: userName,
    siteType: 0,
  };
  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  let response: Response;
  try {
    response = await fetch(route, options);
  } catch (e) {
    console.error('Unexpected search fetch error', e);
    return;
  }

  if (response.status !== 200) {
    console.error(`Failed to send email with code ${response.status}`);
  }

  return response.status;
}

export async function changePassword(code: string, newPassword: string) {
  const route = generateApiUrl(USER_PATH_PATTERNS.CHANGE_PASSWORD);

  const headers = {
    'Content-Type': 'application/json',
  };

  const body = {
    code,
    newPassword,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };

  let response: Response;
  try {
    response = await fetch(route, options);
  } catch (e) {
    console.error('Unexpected search fetch error', e);

    return;
  }

  let json: any;
  try {
    json = response.json();
  } catch (e) {
    console.error('Unable to serialize search result', e);

    return;
  }

  return json.status;
}

export async function userLogin(username: string, password: string) {
  const route = generateApiUrl(USER_PATH_PATTERNS.LOGIN);

  const headers = {
    'Content-Type': 'application/json',
  };

  const body = {
    username,
    password,
  };

  const options = {
    method: 'POST',
    body: JSON.stringify(body),
    headers,
  };
  const response = await fetch(route, options);
  const json = await response.json().catch(console.error);

  if (process.env.NODE_ENV !== 'development') {
    if (response.status !== 200) {
      console.error(`Login request failed with status ${response.status}`, json);
    }

    return json as UserLoginResponse;
  }

  if (response.status !== 200) {
    if (validateResponseErrorsList(json)) {
      console.error(`Token request failed with status ${response.status}:\n${json.message || ''}`, json.errors);
    } else if (validateResponseErrorMessage(json)) {
      console.error(`Token request failed with status ${response.status}:\n${json.message}`);
    } else {
      console.error(`Token request failed with status ${response.status}`, json);
    }
    return undefined;
  }

  return json as UserLoginResponse;
}
