import { useState } from 'react';

import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import SwitchAccount from '@mui/icons-material/SwitchAccount';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';

interface SwitchRoleMenuItemProps {
  switchActiveRole: (role: string) => void,
  text: string
  availableRoles: string[]
}

function SwitchRoleMenuItem({ switchActiveRole, text, availableRoles }: SwitchRoleMenuItemProps) {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const handleClick = () => {
    setExpanded(!expanded);
  };

  return (
    <>
      <MenuItem onClick={handleClick}>
        <ListItemIcon>
          <SwitchAccount fontSize="small" />
        </ListItemIcon>

        <ListItemText>{text}</ListItemText>

        {expanded ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>

      <Collapse
        in={expanded}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
        >
          {availableRoles.map((role) => {
            const translatedRoleName = `profileMenu.${role.replace(/-./g, (x) => x[1].toUpperCase())}`;
            return (
              <MenuItem
                key={role}
                aria-label={role}
                sx={{ pl: 4 }}
                onClick={() => switchActiveRole(role)}
              >
                <ListItemText primary={t(translatedRoleName)} />
              </MenuItem>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default SwitchRoleMenuItem;
