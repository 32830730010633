import ListItemText from '@mui/material/ListItemText';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import { NavOption } from 'src/types/NavOption';

interface MainHeaderMobileMenuProps {
  menuId: string,
  anchorEl: HTMLElement | null,
  handleMenuClose: () => void,
  navOptions: NavOption[]
}

function MainHeaderMobileMenu({
  menuId, anchorEl, handleMenuClose, navOptions,
}: MainHeaderMobileMenuProps) {
  const { t } = useTranslation();
  const isMobileMenuOpen = Boolean(anchorEl);
  const navigate = useNavigate();
  const location = useLocation();

  const handleClick = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>, href: string) => {
    event.preventDefault();
    navigate(href);
    handleMenuClose();
  };

  return (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMenuClose}
    >
      {navOptions.map((navOption: { path: string, translationKey: string }) => (
        <MenuItem
          selected={navOption.path === location.pathname}
          key={navOption.path}
        >
          <ListItemText>
            <a
              onClick={(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => handleClick(e, navOption.path)}
              href={navOption.path}
              style={{ textDecoration: 'none' }}
            >
              {t(navOption.translationKey)}
            </a>
          </ListItemText>
        </MenuItem>
      ))}
    </Menu>
  );
}

export default MainHeaderMobileMenu;
