// BASE COLORS
// Base colors are not meant to be exported.
// Their variable names come from Figma or Material Design palette.
// eg https://www.figma.com/file/TvnfcR4b15UPsSTVAxy0EK/Wiley-Design-System?node-id=160%3A8

export const blueGreen = {
  50: '#E7F6F8',
  100: '#D0EEF1',
  200: '#A2DDE4',
  300: '#73CDD7',
  400: '#45BCCA',
  600: '#008392',
  700: '#006975',
  800: '#004b54',
  900: '#002d32',
};

// THEME PALETTE `blue` OBJECT
export const blue = {
  50: '#DFF6FF',
  100: '#97E0FF',
  200: '#75D5FF',
  300: '#47C8FF',
  400: '#09B5FF',
  500: '#1C79CD',
  600: '#0F668B',
  700: '#125571',
  800: '#14465C',
  900: '#143B4B',
};

// THEME PALETTE `indigo` OBJECT
export const indigo = {
  50: '#EFF7FF',
  100: '#C5D5FF',
  200: '#9EAFEB',
  300: '#788BC4',
  400: '#54689F',
  500: '#30487B',
  600: '#213C6E',
  700: '#113161',
  800: '#002755',
  900: '#131F45',
};

// THEME PALETTE `purple` OBJECT
export const purple = {
  50: '#F5F5FF',
  100: '#DADCF5',
  200: '#C1C6EF',
  300: '#A9AFE8',
  400: '#9098E2',
  500: '#7882DC',
  600: '#636BB5',
  700: '#4D538C',
  800: '#373C64',
  900: '#21243C',
};

// THEME PALETTE `grey` OBJECT
export const grey = {
  0: '#FFFFFF',
  50: '#F8F8F8',
  100: '#DDDDDD',
  200: '#C4C4C4',
  300: '#ABABAB',
  400: '#727272',
  500: '#585858',
  600: '#484848',
  700: '#383838',
  800: '#2E2E2E',
  900: '#212121',
  1000: '#121212',
};

// THEME PALETTE `red` OBJECT
export const red = {
  50: '#FBEBEA',
  100: '#E7C1C0',
  200: '#DEACAC',
  300: '#D49190',
  400: '#C56C6B',
  500: '#A94442',
  600: '#874241',
  700: '#703B3A',
  800: '#5D3434',
  900: '#4D2E2E',
};

// THEME PALETTE `green` OBJECT
export const green = {
  50: '#E6F1E6',
  100: '#B9DCBA',
  200: '#A2D0A3',
  300: '#83C084',
  400: '#5AAB5B',
  500: '#3C763D',
  600: '#385F39',
  700: '#314F32',
  800: '#2B422B',
  900: '#263726',
};

// THEME PALETTE `yellow` OBJECT
export const yellow = {
  50: '#FEFAF0',
  100: '#FCF0D2',
  200: '#FAE7B4',
  300: '#F8DD97',
  400: '#F6D479',
  500: '#F5CB5C',
  600: '#C9A74C',
  700: '#9C823B',
  800: '#705D2A',
  900: '#43381A',
};

export const sepia = {
  50: '#F4ECD8',
  100: '#F4E7CB',
  200: '#ECE2CA',
  400: '#DDCFAD',
  500: '#D8C8A3',
  600: '#4E4813',
};

// THEME PALETTE `white` OBJECT
export const white = {
  7: 'rgba(255, 255, 255, 0.07)',
  16: 'rgba(255, 255, 255, 0.16)',
  40: 'rgba(255, 255, 255, 0.4)',
  60: 'rgba(255, 255, 255, 0.6)',
  100: 'rgba(255, 255, 255, 1)',
};
