/**
  * this component adds a maximum width to the page content, 
  * in case of larger screens the content doesn't keep expanding. 
  * So we need to use this component around the contents of each page.
 */

import { ReactNode } from 'react';

import Container from '@mui/material/Container';
import { DEFAULT_MARGIN } from 'src/config/theme/constants/spacing';

interface Props {
  children: ReactNode,
  disableOnMobile?: boolean,
  className?: string
}

const MaxWidthContainer = ({ children, disableOnMobile = false, className }: Props) => (
  <Container
    disableGutters
    maxWidth="xl"
    sx={{
      px: {
        xs: disableOnMobile ? 0 : DEFAULT_MARGIN.mobile,
        sm: DEFAULT_MARGIN.desktop,
      },
    }}
    className={className}
  >
    {children}
  </Container>
);

export default MaxWidthContainer;
