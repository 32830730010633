import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Skeleton from '@mui/material/Skeleton';
import { LoggedUser } from 'src/types/LoggedUser';

interface MainHeaderProfileIconProps {
  profileMenuId: string,
  handleProfileMenuOpen: (event: React.ChangeEvent<any>) => void,
  loggedUser?: LoggedUser
}

function MainHeaderProfileIcon({ profileMenuId, handleProfileMenuOpen, loggedUser }: MainHeaderProfileIconProps) {
  return (
    <IconButton
      size="large"
      edge="end"
      aria-label="account of current user"
      aria-controls={profileMenuId}
      aria-haspopup="true"
      onClick={handleProfileMenuOpen}
      color="inherit"
    >
      {loggedUser ? (
        <Avatar
          alt={`${loggedUser.firstName} ${loggedUser.lastName}`}
          src={loggedUser.avatarUrl ?? undefined}
        >
          {loggedUser.firstName?.at(0)}

          {loggedUser.lastName?.at(0)}
        </Avatar>
      ) : (
        <Skeleton
          animation="wave"
          variant="circular"
          width={40}
          height={40}
          sx={{ backgroundColor: 'grey.300' }}
        />
      )}
    </IconButton>
  );
}

export default MainHeaderProfileIcon;
