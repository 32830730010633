import { createTheme, Theme } from '@mui/material/styles';

import * as customPalette from './constants/colors';
import {
  fontFamily,
  body1SemiBold,
  contentDescription,
  contentText,
  contentTitle,
  durationText,
  totalResultCount,
} from './constants/typography';

export const defaultPrimaryColor = '#1B5BA0';
export const defaultSecondaryColor = '#113B25';

const themeBuilder = (primaryColor: string, secondaryColor: string) => ({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  // Custom values used in application components
  custom: {
    palette: customPalette,
  },
  // MUI overrides
  palette: {
    primary: {
      main: primaryColor,
    },
    secondary: {
      main: secondaryColor,
    },
    grey: customPalette.grey,
    tonalOffset: {
      light: 0.7,
      dark: 0.3,
    },
  },
  typography: {
    fontFamily,
    body1SemiBold,

    // Do not do this!
    // We do not need separate typography for each element
    // TODO Remove this variants without affecting component looks
    contentDescription,
    contentText,
    contentTitle,
    durationText,
    totalResultCount,
  },
  components: {
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
});

// We need to tell typescript, that we have custom values in theme
declare module '@mui/material/styles' {
  type CustomTheme = ReturnType<typeof themeBuilder>['custom'];

  interface Theme {
    custom: CustomTheme
  }
  interface ThemeOptions {
    custom?: CustomTheme
  }
}

// Declare typography custom variant overrides
// Usage is questionable, thus it is a **bad** example to follow
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    body1SemiBold: true;

    // Do not do this!
    // We do not need separate typography for each element
    // TODO Remove this variants without affecting component looks
    contentDescription: true;
    contentText: true;
    contentTitle: true;
    durationText: true;
    totalResultCount: true;
  }
}

export const createPlatformTheme = (primaryColor: string, secondaryColor: string): Theme => createTheme(
  themeBuilder(primaryColor, secondaryColor),
);
