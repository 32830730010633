/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/ResponseErrorsList.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/ResponseErrorsList.json';

export interface ResponseErrorsList {
  /**
   * Array containing objects describing each of the errors that occurred during the request processing.
   */
  errors?: {
    /**
     * The name of the field where the error occurred.
     */
    name?: string;
    /**
     * The origin of the error.
     */
    origin?: 'body' | 'query';
    /**
     * The type of the error, respecting the 'errors/{type}' format.
     */
    type?: 'errors/invalid-value';
    /**
     * Extra information regarding the error context.
     */
    detail?: string;
    [k: string]: unknown;
  }[];
  [k: string]: unknown;
}

export const validateResponseErrorsList = (data: any): data is ResponseErrorsList => ajv.validate(schema, data);
