import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function UserNameMenuItem({ text }: { text: string }) {
  return (
    <Box sx={{ mx: 2, mb: 1 }}>
      <Typography sx={{ fontWeight: 600 }}>
        {text}
      </Typography>
    </Box>
  );
}

export default UserNameMenuItem;
