/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/ResponseErrorMessage.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/ResponseErrorMessage.json';

export interface ResponseErrorMessage {
  /**
   * A message explaining why the request was not accepted.
   */
  message?: string;
  [k: string]: unknown;
}

export const validateResponseErrorMessage = (data: any): data is ResponseErrorMessage => ajv.validate(schema, data);
