import Email from '@mui/icons-material/Email';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

function EmailMenuItem({ text }: { text: string }) {
  return (
    <Box sx={{ mx: 2, display: 'flex' }}>
      <Email
        fontSize="small"
        sx={{ mr: 1, color: 'rgba(0,0,0,0.54)' }}
      />

      <Typography sx={{ fontSize: '12px' }}>{text}</Typography>
    </Box>
  );
}

export default EmailMenuItem;
