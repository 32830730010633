/* eslint-disable max-len */
/**
 * This file was generated by compileSchemas.ts script  
 * DO NOT modify it directly  
 * Instead make changes inside ../schemas/ImageResponse.json and run  
 * npm run schema
 */

import ajv from './ajv';
import schema from '../schemas/ImageResponse.json';

/**
 * Object containing the thumbnail of training program
 */
export interface ImageResponse {
  /**
   * The name of the image
   */
  fileName?: string;
  /**
   * The URL on S3 of the image
   */
  url?: string;
  [k: string]: unknown;
}

export const validateImageResponse = (data: any): data is ImageResponse => ajv.validate(schema, data);
