import { Route, Routes } from 'react-router-dom';

import ProtectedRoute from './ProtectedRoute';
import { ROUTES_DEFINITIONS } from './routes';

function Router() {
  return (
    <Routes>
      {Object.entries(ROUTES_DEFINITIONS).map(([routeName, route]) => (
        <Route
          key={routeName}
          element={(
            <ProtectedRoute
              requiresAuth={route.requiresAuth}
              allowedRoles={route.requiresAuth ? route.allowedRoles : '*'}
            />
          )}
        >
          <Route
            path={route.path}
            element={route.renderComponent()}
          />
        </Route>
      ))}
    </Routes>
  );
}

export default Router;
