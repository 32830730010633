// TYPOGRAPHY
// https://material-ui.com/customization/typography/#typography

// FONT ATTRIBUTES
export const fontFamily = 'Open Sans';
const fontSizeMedium = '0.875rem';
const fontSizeRegular = '1rem';
const fontSizeSmall = '0.75rem';

const fontWeightSemiBold = 600;

export const body1SemiBold = {
  fontSize: fontSizeRegular,
  lineHeight: 1.5,
  fontWeight: fontWeightSemiBold,
  fontFamily,
};

export const contentDescription = {
  fontFamily,
  fontSize: fontSizeSmall,
  lineHeight: 1.33,
  '@media (min-width: 600px)': {
    fontSize: fontSizeMedium,
    lineHeight: 1.43,
  },
};

export const contentText = {
  fontFamily,
  fontSize: fontSizeSmall,
  lineHeight: 1.33,
};

export const contentTitle = {
  fontFamily,
  fontSize: fontSizeRegular,
  fontWeight: fontWeightSemiBold,
};

export const durationText = {
  fontFamily,
  fontSize: fontSizeSmall,
  fontWeight: fontWeightSemiBold,
  lineHeight: 1.33,
};

export const totalResultCount = {
  fontFamily,
  fontSize: fontSizeMedium,
  lineHeight: 1.43,
};
