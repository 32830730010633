import { Box, CircularProgress } from '@mui/material';
import { defaultPrimaryColor } from 'src/config/theme/theme';

import './BackdropLoading.scss';

export default function BackdropLoading() {
  return (
    <Box className="BackdropLoading">
      <CircularProgress
        disableShrink
        size={70}
        thickness={4}
        sx={{ color: defaultPrimaryColor }}
      />
    </Box>
  );
}
