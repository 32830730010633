export enum Role {
  ORG_ADMIN = 'OrgAdmin',
  LEARNER = 'Learner',
  FACILITATOR = 'Facilitator',
  INSTRUCTIONAL_DESIGNER = 'InstructionalDesigner',
  REPORTING_USER = 'ReportingUser',
}

export interface OrganizationDetails {
  id: number,
  userId: number,
  organizationId: number,
  role: Role
}

export interface OrganizationRolesResponse {
  items?: OrganizationDetails[];
  count?: number;
  _links?: {
    [k: string]: unknown;
  };
  [k: string]: unknown;
}
